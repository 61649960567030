import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import store from "../store";
import { defaultUser } from "@/store/state";
import UserService from "../services/UserService";
import SnakbarModel from "shared-components/src/models/SnakbarModel";
import { Profile } from "shared-components/src/definitions/config";
import { messaging, firebaseVapidKey } from "shared-components/src/plugins/firebase";

let authenticatedRoute = "";

function needAuthentication(to: any): boolean {
  if ((to.meta && to.meta.authorize) || to.fullPath.includes("team-member")) {
    return true;
  }
  for (const r of to.matched) {
    if ((r.meta && r.meta.authorize) || r.path.includes("team-member")) {
      return true;
    }
  }
  return false;
}

function authGuard(to: any, next: any): void {
  const authorize = needAuthentication(to);
  const newLocation = getRedirectLocation(to, authorize);
  if (authorize) {
    if (store.getters.userIsTeamMember) {
      newLocation ? next(newLocation) : next();
    } else {
      if (store.getters.userIsAuthenticated) {
        const snakbar: SnakbarModel = {
          id: "",
          body: "Your account is not a teammember account",
          status: "warning",
          button: "ok",
          show: true,
          timeout: Profile.ErrorSetting.SnackbarTimeout,
          handler: (snakbarId: any) => store.commit("REMOVE_SNAKBAR", snakbarId),
        };
        store.commit("SET_SNAKBAR_MODEL", snakbar);
      }
      next("/register/logout");
    }
  } else {
    if (!store.getters.userIsTeamMember || to.name == "verifyEmail") {
      newLocation ? next(newLocation) : next();
    } else {
      next("/team-member");
    }
  }
}
function profileNotCompleted() {
  return (
    !store.getters.hasPersonalDetail || !store.getters.hasProfessionalDetail || !store.getters.hasCareerHistory || !store.getters.hasProfileDetail
  );
}
function getRedirectLocation(to: any, needAuth: boolean) {
  let newLocation;
  if (to.path === "/team-member/position/apply") {
    if (to.query["position"] != undefined) {
      localStorage.setItem("positionInfo", to.query.position);
    }
  }
  if (needAuth && (!store.state.userInfo || !store.state.userInfo.isAuthenticated)) {
    newLocation = `/register/login?returnUrl=${to.path}&r=1`;
  } else if (needAuth) {
    if (profileNotCompleted()) {
      newLocation = "/team-member/completeProfile";
      store.state.showLoading = false;
    } else if (store.state.userInfo.emailVerified && to.name === "completeProfile" && localStorage.getItem("positionInfo") === null) {
      newLocation = "/team-member/profile";
      store.state.showLoading = false;
    } else {
      if (!store.state.userInfo.emailVerified && to.name != "emailVerification" && to.name != "verifyEmail") {
        newLocation = `/team-member/email-verification?returnUrl=${to.path}`;
        store.state.showLoading = false;
      } else if (to.meta && to.meta.roles) {
        let found = false;
        if (store.state.userInfo && store.state.userInfo.roles) {
          found = to.meta.roles.includes(store.state.userInfo.roles);
        }
        if(found && localStorage.getItem("positionInfo") != null ) {
          newLocation = `/team-member/position/apply?position=${localStorage.getItem("positionInfo")}`;
          delete localStorage["positionInfo"];
        }
        if (!found) {
          newLocation = `/register/logout?returnUrl=${to.path}&r=2`;
        }
      }
    }
  }
  return newLocation;
}

function changeDocumentTitle(to: any) {
  if (typeof document !== "undefined") {
    document.title = to.meta && to.meta.title ? `${to.meta.title}` : `Project Portal`;
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("showLoading");
  store.commit("CLOSE_PROFILE_DROPDOWN");
  store.commit("CLOSE_NOTIFICATION_DROPDOWN");
  if (!to.fullPath.includes("/team-member/timesheet")) {
    localStorage.dayOffset = 0;
  }
  if (to && to.meta && to.meta.title === "Logout") {
    await store.dispatch("setUserInfo", { ...defaultUser, vm: null });
  }

  changeDocumentTitle(to);
  if (to && to.meta && to.meta.frontAuthRequired && !store.state.hasFirebaseAuth) {
    await UserService.loginWithToken();
    store.state.hasFirebaseAuth = true;
  }
  if (authenticatedRoute !== to.fullPath) {
    authenticatedRoute = to.fullPath;
    authGuard(to, next);
  } else {
    next();
  }
});

router.afterEach(() => {
  // store.commit("REMOVE_ALL_SNAKBARS");
  store.dispatch("hiddenLoading");
});

export default router;
