<template>
  <v-app :class="{ 'no-vapp-scroll': showLoading }">
    <loading :isLoading="showLoading">
      <router-view />
    </loading>
    <v-snackbar v-for="(snakbar, index) in getSnakbars" left class="snakbar" bottom :key="snakbar.id"
      :color="snakbar.status" v-model="snakbar.show" :timeout="snakbar.timeout"
      :style="`bottom: ${index * 60 + 10}px;`">
      {{ snakbar.body }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" ripple text v-bind="attrs" @click="snakbarActionClicked(snakbar.id)"> {{ snakbar.button
          }}</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { mapGetters, mapMutations } from "vuex";
import Loading from "shared-components/src/components/RootLoading.vue";
import { REMOVE_ALL_SNAKBARS } from "@/store/types";
import store from "./store/index";
import { requestNotificationPermission } from '@/services/NotificationService';

export default defineComponent({
  components: {
    Loading,
  },
  data() {
    return {
      connectionLost: false,
      timeout: 0,
    };
  },
  computed: {
    ...mapGetters(["showLoading", "getSnakbars", "getSnakbar"]),
  },
  created() {
    this.REMOVE_ALL_SNAKBARS();
  },
  async mounted() {
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        store.commit("UPDATE_NOTIFICATION_LIST");
      }
    });
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          requestNotificationPermission();
        })
        .catch((err) => {
          console.error("Service Worker registration failed: ", err);
        });
    } else {
      console.error("Service Workers are not supported in this browser.");
    }
  },
  methods: {
    ...mapMutations([REMOVE_ALL_SNAKBARS]),
    snakbarActionClicked(id: string) {
      this.$emit("snakBarClicked", id);
      if (this.getSnakbar(id).handler) {
        this.getSnakbar(id).handler(id);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.snakbar {
  transition: all 0.1s;
}
</style>
