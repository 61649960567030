<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        Loan List
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn class="primary_btn_v2 action-btn" @click="showDialog = true"> <v-icon>mdi-plus</v-icon> Loan Request</v-btn>
      </v-row>
    </v-card-title>
    <v-card-text class="loan-body">
      <v-data-table-server
        :items="items"
        :headers="headers"
        :loading="loading"
        :page="page"
        :items-per-page="numberOfPages"
        @update:options="(event: any) => tableOptions = event"
        :items-length="totalItemsCount"
      >
        <template v-slot:item.CreatedAt="{ item }">
          <strong>{{ formatDate(item.CreatedDate) }}</strong>
        </template>
        <template v-slot:item.ProposedPaymentDate="{ item }">
          <strong>{{ formatDate(item.ProposedPaymentDate) }}</strong>
        </template>
        <template v-slot:item.Amount="{ item }">
          <span>{{ formatNumber(item.Amount) }}</span>
        </template>
        <template v-slot:item.UnPaidAmount="{ item }">
          <span>{{ formatNumber(item.UnPaidAmount) }}</span>
        </template>
        <template v-slot:item.Installment="{ item }">
          <span>{{ item.Installment }} [{{ formatNumber(getInstallmentAmount(item)) }}]</span>
        </template>
        <template v-slot:item.PaymentReference="{ item }">
          <span>{{ item.PaymentReference }}</span>
        </template>
        <template v-slot:item.StartPaymentDate="{ item }">
          <span>{{ formatDate(item.StartPaymentDate) }}</span>
        </template>
        <template v-slot:item.Description="{ item }">
          <span>{{ item.Description + " [" + item.Reference + "]" }}</span>
        </template>
        <template v-slot:item.Status="{ item }" class="text-center">
          <v-chip v-if="item.Status == loanStatuses.Pending" class="ma-2" color="orange"> Pending </v-chip>
          <v-chip v-if="item.Status == loanStatuses.Approved" class="ma-2" color="success"> Approved </v-chip>
          <v-chip v-if="item.Status == loanStatuses.Rejected" class="ma-2" color="error"> Rejected </v-chip>
          <v-chip v-if="item.Status == loanStatuses.FullyPaid" class="ma-2" color="green"> Fully Re-Paid </v-chip>
        </template>
        <template v-slot:item.Actions="{ item }">
          <span class="operation text-center">
            <v-menu location="bottom" v-if="canBeDeleted(item)">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item @click="confirmDelete(item)" prepend-icon="mdi-delete" title="Delete" />
              </v-list>
            </v-menu>
          </span>
        </template>
        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan="columns.length" class="data-table-expanded-content custom-loan-row pa-2">
            <div>
              <v-row class="ma-0">
                <v-col md="2"> Amount </v-col>
                <v-col md="6"> Description </v-col>
                <v-col md="4"> Payment Date </v-col>
              </v-row>
              <div
                v-for="overviewItem in item.PaymentOverview"
                :key="overviewItem.Description"
                :class="[`timesheet-row`, overviewItem.IsPaid ? 'b-light-green text-white' : '']"
              >
                <v-row class="ma-0">
                  <v-col md="2">
                    {{ formatNumber(overviewItem.Amount) }}
                  </v-col>
                  <v-col md="6">
                    {{ overviewItem.Description }}
                  </v-col>
                  <v-col md="4">
                    {{ formatDate(overviewItem.PaidDate) }}
                  </v-col>
                </v-row>
              </div>
            </div>
          </td>
        </template>
      </v-data-table-server>
      <v-alert v-if="totalAmountAUD" type="info" class="text-right">
        <strong>Amount AUD: </strong> {{ formatNumber(totalAmountAUD) }} <br /><strong>Remaining Amount AUD: </strong>
        {{ formatNumber(totalRemainingAmountAUD) }}
      </v-alert>
    </v-card-text>
    <v-dialog v-model="showDialog" @keypress.esc="showDialog = false" max-width="1200px">
      <LoanRegister
        v-if="showDialog"
        :id="selectedId"
        :loading="loading"
        @cancel="
          selectedId = null;
          showDialog = false;
        "
        @upsert="upsert"
      />
    </v-dialog>
    <v-dialog persistent v-model="showDeleteConfirmDialog" @keydown.esc="showDeleteConfirmDialog = false" max-width="400px">
      <confirm-dialog
        :loading="deleteLoading"
        @confirm="deleteItem"
        @cancel="
          showDeleteConfirmDialog = false;
          deleteLoading = false;
        "
        :message="confirmModel"
      >
      </confirm-dialog>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoanRegister from "./LoanRegister.vue";
import LoanService from "shared-components/src/services/LoanService";
import Utils from "shared-components/src/utils/Utils";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { LoanVM, LoanStatus } from "shared-components/src/models/Loan";
import PaginationRequestModel from "shared-components/src/viewModels/request/PaginationRequestModel";
import store from "@/store";

export default defineComponent({
  components: { LoanRegister, ConfirmDialog },
  data() {
    return {
      loanStatuses: LoanStatus,
      itemRemoveId: -1,
      showDeleteConfirmDialog: false,
      selectedId: null as string | null,
      loading: false,
      deleteLoading: false,
      showDialog: false,
      items: undefined as any | undefined,
      confirmModel: {
        text: "Are you sure?",
      },
      headers: [
        { title: "", value: "data-table-expand" },
        { title: "Request Date", sortable: true, align: "start", value: "CreatedAt" },
        { title: "Requested Start Date", sortable: true, align: "start", value: "ProposedPaymentDate" },
        { title: "Amount AUD", sortable: true, value: "Amount", align: "right" },
        { title: "Remaining AUD", sortable: false, value: "UnPaidAmount", align: "right" },
        { title: "Number Of Installments", sortable: false, value: "Installment", align: "center" },
        { title: "Payment Reference", sortable: false, value: "PaymentReference", align: "center" },
        { title: "Payment Date", sortable: true, value: "StartPaymentDate", align: "center" },
        { title: "Description", value: "Description", sortable: false },
        { title: "Status", sortable: false, value: "Status", align: "center" },
        { title: "Actions", sortable: false, value: "Actions", align: "center" },
      ],
      page: 1,
      totalItemsCount: 0 as number | undefined,
      totalAmountAUD: 0 as number | undefined,
      totalRemainingAmountAUD: 0 as number | undefined,
      numberOfPages: 10,
      sortProp: "ProposedPaymentDate",
      sortOrder: "desc",
      tableOptions: {} as any,
    };
  },
  methods: {
    canBeDeleted(item: any) {
      return LoanService.CanBeDeleted(item);
    },
    confirmDelete(item: any) {
      this.selectedId = item.id;
      this.showDeleteConfirmDialog = true;
    },
    async deleteItem() {
      try {
        this.deleteLoading = true;
        if (this.selectedId != null) {
          await LoanService.delete(this.selectedId);
          store.dispatch("showSuccessMessage", "Loan has been successfully deleted!");
          this.fetchLoans();
          this.showDeleteConfirmDialog = false;
        } else {
          store.dispatch("showErrorMessage", "Loan has not been selected yet");
        }
      } catch (error: any) {
        const errorMessage = error && error.response && error.response.data;
        store.dispatch("showErrorMessage", errorMessage);
        this.showDeleteConfirmDialog = false;
      } finally {
        this.deleteLoading = false;
      }
    },
    getInstallmentAmount(row: LoanVM) {
      return row.Amount / row.Installment;
    },
    formatDate(date: Date): string {
      return `${Utils.toVsDateFormat(date)}`;
    },
    formatNumber(value: number): string {
      return `$${Utils.moneyFormat(value)}`;
    },
    edit(index: any): void {
      this.selectedId = index;
      this.showDialog = true;
    },
    async upsert(model: LoanVM): Promise<void> {
      try {
        if (model.ProposedPaymentDate) {
          model.ProposedPaymentDate = Utils.addTimezoneOffset(model.ProposedPaymentDate);
        }
        this.loading = true;
        if (model.id) {
          await LoanService.update(model);
        } else {
          await LoanService.add(model);
        }
        store.dispatch("showSuccessMessage", "Loan saved successfully!");
      } catch (error) {
        this.showDialog = false;
        store.dispatch("showErrorMessage", "A loan upsert error has occurred!");
      } finally {
        this.showDialog = false;
        this.selectedId = null;
        this.loading = false;
        this.fetchLoans();
      }
    },
    async fetchLoans() {
      try {
        this.loading = true;
        const { page, itemsPerPage } = this.tableOptions;

        const model = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
        } as PaginationRequestModel;
        const result = await LoanService.getList(model);
        this.items = result.data;
        this.totalItemsCount = result.totalCount;
        this.totalAmountAUD = result.TotalAmountAUD;
        this.totalRemainingAmountAUD = result.TotalRemainingAmountAUD;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0].key;
        }

        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "asc";
        }
        this.fetchLoans();
      }
    },
  },
});
</script>
<style scoped lang="scss">
.total-table {
  width: 100%;
}
.custom-loan-row {
  width: 700px;
  .v-col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}

@media (max-width: 581px) {
  .loan-body {
    padding-top: 50px;
  }
  .action-btn {
    margin-top: 15px;
    width: 100%;
  }
}
</style>
