<template>
  <div id="profileSetupTab">
    <v-row>
      <v-col cols="12" class="text-center text-md-left">
        <h1>Profile Photo and Resume</h1>
      </v-col>
      <v-col cols="12">
        <InfoBox title="Upload a Profile Photo and a Copy of your Resume" />
      </v-col>
      <v-col md="6" lg="4" cols="12">
        <UploadProfilePhoto :personalDetail="personalDetail" @setImage="setImage" :isFileValid="validFile" />
      </v-col>
      <v-col md="6" lg="4" cols="12">
        <UploadResume :personalDetail="personalDetail" :isFileValid="validResume" @setResume="setResume" />
      </v-col>
      <v-col cols="12" class="text-center text-md-left">
        <h1>Links</h1>
      </v-col>
      <v-form ref="frmProfileDetail" lazy-validation>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12"><InfoBox title="Submit any Links to your work or personal sites here!" /></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <span class="f-body-1">Enter your Links Here </span>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <TextField :readonly="isHiringmanager" placeholder="Linkedin address" dense outlined v-model="linkedIn" />
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <TextField :readonly="isHiringmanager" dense outlined placeholder="GitHub address" v-model="github" />
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <TextField :readonly="isHiringmanager" dense outlined placeholder="Personal Website/Portfolio" v-model="website" />
                </v-col>
                <!-- <v-col cols="12" md="4" sm="12">
                  <AutoCompleteField
                    :readonly="isHiringmanager"
                    dense
                    outlined
                    :items="militaryServiceSituations"
                    placeholder="Military Service Situation"
                    label="Military Service Situation"
                    v-model="personalDetail.MilitaryServiceSituation"
                  ></AutoCompleteField>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <AutoCompleteField
                    :readonly="isHiringmanager"
                    dense
                    outlined
                    :items="maritalStatus"
                    placeholder="Marital Status"
                    label="Marital Status"
                    v-model="personalDetail.MaritalStatus"
                  >
                  </AutoCompleteField>
                </v-col>
                <v-col cols="12" md="4" sm="12">
                  <label class="input-label pt-3 mb-0"> Are you currently a student? <small class="mt-3 error--text">*</small> </label>
                  <v-radio-group
                    :readonly="isHiringmanager"
                    class="pt-0"
                    :rules="[validations.required]"
                    row
                    v-model="personalDetail.StillStudying"
                    column
                  >
                    <v-radio label="Yes" value="true"></v-radio>
                    <v-radio label="No" value="false"></v-radio>
                  </v-radio-group>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center text-md-left">
          <h1>Bio</h1>
        </v-col>
        <v-col md="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <InfoBox title="Write a Brief Bio to introduce yourself. This Will be used for your public profile" />
                </v-col>
                <v-col cols="12">
                  <TextAreaFieldWithAIGenerator
                    :options="questions"
                    id="PersonalDetailDescription"
                    counter
                    :readonly="false"
                    placeholder="Write a brief summary of your professional experience"
                    :rules="[validations.required, validations.minCharDesc]"
                    v-model="description"
                    showRequired
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center text-md-left">
          <h1>Personality</h1>
        </v-col>
        <v-col md="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <InfoBox
                    title="Tell us a little about your personality. it helps us to know more about you! (this will not be displayed on your profile)"
                  />
                </v-col>
                <v-col cols="12">
                  <TextAreaFieldWithAIGenerator
                    :options="options"
                    :readonly="isHiringmanager"
                    id="ExperienceDetailDescription"
                    placeholder="E.G: What are your strengths and weaknesses? What is your demeanour at work, are you introverted/extroverted, do you like to work in teams or do you prefer to work solo?"
                    counter
                    label="How would you describe your personality?"
                    :rules="[validations.required, validations.minCharDesc]"
                    v-model="personality"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-form>
    </v-row>
    <v-card-actions v-if="isAfterSignUp">
      <div class="wizard-action">
        <v-tooltip top attach>
          <template v-slot:activator="{ props }">
            <v-btn
              class="secondary_btn"
              v-bind="props"
              @click="submitForm(true)"
              :disabled="submitting"
              :loading="submitting"
              prepend-icon="mdi-check"
              >Save and exit</v-btn
            >
          </template>
          <span>You can save your progress and return to the application process later</span>
        </v-tooltip>
        <div class="d-flex ga-5">
          <v-btn class="primary_btn actionButton" @click="goBack()" :disabled="loading" :loading="loading" prepend-icon="mdi-chevron-left"
            >Back</v-btn
          >
          <v-btn
            class="primary_btn actionButton"
            @click="submitForm(false)"
            :disabled="submitting"
            :loading="submitting"
            append-icon="mdi-chevron-right"
            >Finish</v-btn
          >
        </div>
      </div>
    </v-card-actions>
    <v-card-actions v-else class="mt-6">
      <v-col md="12" class="text-center">
        <v-btn class="primary_btn actionButton" @click="submitForm(false)" :disabled="submitting" :loading="submitting" prepend-icon="mdi-check"
          >Save</v-btn
        >
      </v-col>
    </v-card-actions>
    <v-dialog v-model="showSaveForLaterConfirm" max-width="500" @keydown.esc="showSaveForLaterConfirm = false">
      <confirm-dialog :loading="submitting" :message="confirmModel" @confirm="save(true)" @cancel="cancelSaving"></confirm-dialog>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import UploadProfilePhoto from "shared-components/src/components/Profile/UploadProfilePhoto.vue";
import validations from "shared-components/src/utils/validations";
import UploadResume from "shared-components/src/components/Profile/UploadResume.vue";
import TextAreaFieldWithAIGenerator from "shared-components/src/components/Fields/TextAreaFieldWithAIGenerator.vue";
import InfoBox from "shared-components/src/components/InfoBox.vue";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import { BiographyQuestions } from "shared-components/src/definitions/constants";

export default defineComponent({
  props: ["teammemberModel", "isAfterSignUp", "loading", "isHiringmanager"],
  components: {
    UploadProfilePhoto,
    UploadResume,
    ConfirmDialog,
    TextAreaFieldWithAIGenerator,
    InfoBox,
  },
  data() {
    return {
      validations,
      questions: BiographyQuestions,
      options: [
        {
          title: "Which one better describes you:",
          selected: "",
          choices: ["done is better than perfect.", "everything has to look perfect."],
        },
        {
          title: "How do you prefer to get feedback from your manager:",
          selected: "",
          choices: ["through formal performance reviews.", "daily/weekly meetings."],
        },
      ],
      downloading: false,
      validFile: true,
      validResume: true,
      submitting: false,
      personalDetail: null as PersonalDetailModel | null,
      resumeFile: null as File | null,
      description: "",
      uploadImage: false,
      linkedIn: null as null | string,
      github: null as null | string,
      website: null as null | string,
      personality: null as null | string,
      confirmModel: {
        title: "",
        text: "",
      },
      showSaveForLaterConfirm: false,
    };
  },
  watch: {
    loading(newVal) {
      this.submitting = newVal;
    },
    teammemberModel(newVal) {
      this.personalDetail = { ...newVal };
      this.setUi();
    },
  },
  mounted() {
    this.personalDetail = { ...this.teammemberModel };
    this.setUi();
  },
  methods: {
    setUi() {
      if (this.personalDetail?.PhotoUrl) {
        this.validFile = true;
      }
      if (this.personalDetail?.CV) {
        this.validResume = true;
      }
      if (this.personalDetail?.Description) {
        this.description = this.personalDetail?.Description;
      }
      if (this.personalDetail?.LinkedIn) {
        this.linkedIn = this.personalDetail?.LinkedIn;
      }
      if (this.personalDetail?.GitHub) {
        this.github = this.personalDetail?.GitHub;
      }
      if (this.personalDetail?.WebSite) {
        this.website = this.personalDetail?.WebSite;
      }
      if (this.teammemberModel?.ProfessionalDetail?.Personality) {
        this.personality = this.teammemberModel?.ProfessionalDetail.Personality;
      }
    },
    cancelSaving() {
      this.showSaveForLaterConfirm = false;
      this.submitting = false;
    },
    setResume(resume: any) {
      this.resumeFile = resume;
    },
    async submitForm(isForLater = false) {
      if (isForLater) {
        this.confirmModel.title = `Save Progress for Later?`;
        this.confirmModel.text =
          "You're almost ready to move on to your personalized screening experience. Are you sure you want to save your progress for later instead?";
        this.showSaveForLaterConfirm = true;
      } else {
        const isValid = await (this.$refs.frmProfileDetail as any).validate();
        this.validFile = this.personalDetail?.PhotoUrl ? true : false;
        this.validResume = this.resumeFile || this.personalDetail?.CV ? true : false;
        if (isValid.valid && this.validFile && this.validResume) {
          this.save();
        } else {
          var el = document.querySelectorAll("#profileSetupTab .error-border:first-of-type, #profileSetupTab .v-messages.error--text:first-of-type");
          this.$emit("scrollToElement", el[0]);
        }
      }
    },
    async save(isForLater = false) {
      this.submitting = true;
      if (this.personalDetail) {
        if (this.resumeFile) {
          this.personalDetail.CV = await TeamMemberService.uploadResume(this.resumeFile);
        }
        this.personalDetail.Description = this.description;
        this.personalDetail.LinkedIn = this.linkedIn ?? "";
        this.personalDetail.GitHub = this.github ?? "";
        this.personalDetail.WebSite = this.website ?? "";

        this.$emit("submitForm", {
          tm: this.personalDetail,
          personality: this.personality,
          isForLator: isForLater,
          isLastStep: true,
        });
      }
      this.cancelSaving();
    },
    goBack() {
      this.$emit("goBack", 5);
    },
    setImage(data: any) {
      if (this.personalDetail) {
        this.personalDetail.PhotoUrl = data;
      }
    },
  },
});
</script>
