<template>
  <!-- <v-app> -->
  <div>
    <v-navigation-drawer
      dark
      fixed
      :temporary="!drawerPermanent"
      :permanent="drawerPermanent"
      :rail="drawerMini"
      v-model="drawerIsOpen"
      clipped-top
      class="app-drawer"
      width="220"
      v-if="emailVerified"
    >
      <div class="logo-container" :class="{ 'logo-container--mini': !drawerIsOpen }">
        <v-app-bar-nav-icon class="toggler-icon" @click="toggleDrawerOpen">
          <i
            :class="{
              'v-icon': true,
              notranslate: true,
              mdi: true,
              'mdi-chevron-right': !drawerIsOpen,
              'mdi-chevron-left': drawerIsOpen,
            }"
          ></i>
        </v-app-bar-nav-icon>
        <img v-if="drawerIsOpen" class="logo" src="@/assets/images/code-clan-logo.svg" />
      </div>
      <NavigationMenu
        :isAuthenticated="isAuthenticated"
        :profileCompleted="profileCompleted"
        :isLinemanager="isLinemanager"
        :userInfo="userInfo"
        :commitments="commitments"
        :hasActiveCommitment="hasActiveCommitment"
      />
    </v-navigation-drawer>
    <v-app-bar app color="primary" class="appBarComponent" height="68">
      <div class="d-flex">
        <v-app-bar-nav-icon class="toggler-icon" v-if="!drawerIsOpen && drawerMini && emailVerified" @click="toggleDrawerOpen">
          <i
            id="navigation-menu-mdi-chevron-right"
            :class="{
              'v-icon': true,
              notranslate: true,
              mdi: true,
              'mdi-chevron-right': true,
            }"
          ></i>
        </v-app-bar-nav-icon>

        <v-app-bar-nav-icon class="hamburgur-toggler" v-if="!drawerIsOpen && !drawerMini && emailVerified" @click="toggleDrawerOpen" />
        <HeaderTitle />
      </div>
      <ProdileDropdown :commitments="commitments" />
    </v-app-bar>
    <v-main ref="mainRef" id="main-body">
      <v-container fluid class="pages-container">
        <transition name="slide-y-transition" mode="out-in">
          <router-view />
        </transition>
      </v-container>
    </v-main>
    <v-dialog v-model="quickLoginDialog" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="headline">Login</v-card-title>
        <v-card-text>
          <p>You must be logged in again because you are logged out</p>
          <LoginForm form-id="main-login-form" @success="onLoginSuccess" @submitting="onSubmitting($event)" />
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="submitting" :loading="submitting" color="green darken-1" text form="main-login-form" type="submit">OK</v-btn>
          <v-btn :disabled="submitting" color="green darken-1" text @click="closeQuickLoginDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- </v-app> -->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import LoginForm from "@/components/User/LoginForm.vue";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import ProdileDropdown from "@/components/User/ProfileDropdown.vue";
import HeaderTitle from "@/components/User/HeaderTitle.vue";
import TeamMemberService from "@/services/TeamMemberService";
import { mapGetters } from "vuex";
import store from "@/store";
import { CommitmentModel, CommitmentModelStatusV2Enum, TeammemberApi } from "shared-components/src/services/openApi";

export default defineComponent({
  components: {
    NavigationMenu,
    LoginForm,
    LoadingComponent,
    ProdileDropdown,
    HeaderTitle,
  },
  data() {
    return {
      mini: false,
      submitting: false,
      commitments: [] as CommitmentModel[],
      hasActiveCommitment: false,
      emailVerified: store.state.userInfo.emailVerified,
    };
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo", teamMemberInfo: "teamMemberInfo" }),
    showLoading(): boolean {
      return store.state.showLoading;
    },
    profileCompleted(): boolean {
      return (
        store.getters.hasPersonalDetail && store.getters.hasProfessionalDetail && store.getters.hasCareerHistory && store.getters.hasProfileDetail
      );
    },
    isAuthenticated(): boolean {
      return store.getters.userIsTeamMember;
    },
    isLinemanager(): boolean {
      return store.getters.userIsLinemanager;
    },
    drawerIsOpen: {
      get(): boolean {
        return store.state.drawerIsOpen;
      },
      set(newValue: boolean) {
        this.handleDrawerOpen(newValue);
      },
    },
    quickLoginDialog() {
      return store.state.quickLoginDialog;
    },
    drawerPermanent(): boolean {
      return (
        this.isAuthenticated && (this.$vuetify.display.name === "lg" || this.$vuetify.display.name === "xl" || this.$vuetify.display.name === "md")
      );
    },
    drawerMini(): boolean {
      return this.drawerPermanent ? (this.drawerIsOpen ? false : true) : false;
    },
  },
  mounted() {
    this.checkTeamMemberInfo();
  },
  beforeCreate() {
    document.body.className = "home";
  },
  methods: {
    async checkTeamMemberInfo() {
      if (this.teamMemberInfo && this.teamMemberInfo.Id) {
        let tmInfo = await TeamMemberService.GetTeammemberWithProfessional();
        if (tmInfo) {
          await store.dispatch("setTeamMemberInfo", { teamMemberInfo: tmInfo });
          if (!this.profileCompleted) {
            this.$router.push("/team-member/completeProfile");
          }
        }
        const theDate = new Date();
        const fullName = (this.teamMemberInfo.FirstName || "") + " " + (this.teamMemberInfo.LastName || "");
        this.teamMemberInfo.FullName = fullName.trim();
        if (this.teamMemberInfo.Id) {
          try {
            var response = (await new TeammemberApi().getCommitmentByTeammemberId()).data as any;
            this.commitments = response.value;
          } finally {
          }
        }
        if (this.commitments && this.commitments.length > 0) {
          this.hasActiveCommitment = this.commitments.some((c) =>
            [CommitmentModelStatusV2Enum.Active, CommitmentModelStatusV2Enum.TeammemberPending].some((s) => s === c.StatusV2)
          );
        }
      } else {
        if (this.userInfo && this.userInfo.id) {
          let tmInfo = await TeamMemberService.GetTeammemberWithProfessional();
          if (tmInfo) {
            await store.dispatch("setTeamMemberInfo", { teamMemberInfo: tmInfo });
            if (!this.profileCompleted) {
              this.$router.push("/team-member/completeProfile");
            }
            const fullName = (tmInfo.FirstName || "") + " " + (tmInfo.LastName || "");
            tmInfo.FullName = fullName.trim();
            if (tmInfo.Id) {
              try {
                var response = (await new TeammemberApi().getCommitmentByTeammemberId()).data as any;
                this.commitments = response.value;
              } finally {
              }
            }
            if (this.commitments && this.commitments.length > 0) {
              this.hasActiveCommitment = this.commitments.some((c) =>
                [CommitmentModelStatusV2Enum.Active, CommitmentModelStatusV2Enum.TeammemberPending].some((s) => s === c.StatusV2)
              );
            }
          }
        }
      }
    },
    closeQuickLoginDialog(): any {
      return store.dispatch("closeQuickLoginDialog");
    },
    toggleDrawerOpen() {
      return store.dispatch("toggleDrawerOpen");
    },
    handleDrawerOpen(value: boolean) {
      return store.dispatch("handleDrawerOpen", value);
    },
    async onLoginSuccess() {
      store.dispatch("showSuccessMessage", "Login was successful. You can continue\nYou may need to refresh the page");
      this.closeQuickLoginDialog();
    },
    onSubmitting(value: any) {
      this.submitting = value;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
.logo-container {
  padding: 0 10px;
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $racecar;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  .toggler-icon {
    width: 24px;
    height: 24px;
  }
  .logo {
    height: auto;
    width: 80px;
    margin-left: 12px;
  }
}
.logo-container--mini {
  width: 56px;
}
.app-drawer {
  background-color: $dark_gray !important;
  background-repeat: no-repeat;
  background-size: auto;
}
.hamburgur-toggler {
  margin-left: 10px !important;
  color: $dark_gray !important;
}
.pages-container {
  min-height: 340px;
  padding: 22px 33px;
}
@media (max-width: 960px) {
  .pages-container {
    padding: 15px 20px;
  }
}

.logo-link {
  height: 100%;
}
</style>
