<template>
  <v-form ref="frmSignup" :id="formId" @submit.prevent="submit" class="form-inputs">
    <v-row>
      <v-col cols="12" md="6">
        <TextField
          v-model="model.firstName"
          dense
          prepend-icon="mdi-account"
          label="First Name"
          placeholder="First Name"
          type="text"
          class="login-field"
          outlined
          :rules="[validations.required]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          dense
          v-model="model.lastName"
          prepend-icon="mdi-account-box"
          label="Last Name"
          placeholder="Last Name"
          type="text"
          class="login-field"
          outlined
          :rules="[validations.required]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <TextField
          dense
          v-model="model.email"
          prepend-icon="mdi-email"
          label="Email"
          placeholder="Email"
          type="text"
          class="login-field"
          :rules="[validations.required, validations.EmailCheck]"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <TextField
          dense
          v-model="model.telephone"
          prepend-icon="mdi-phone"
          label="Phone Number"
          placeholder="Phone Number"
          type="text"
          class="login-field"
          :rules="[validations.required, validations.phoneNumber]"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <TextField
          dense
          autocomplete="new-password"
          v-model="model.password"
          prepend-icon="mdi-key"
          label="Password"
          placeholder="Password"
          type="password"
          class="login-field"
          :rules="[validations.required]"
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <TextField
          dense
          autocomplete="new-password"
          v-model="model.repassword"
          prepend-icon="mdi-key"
          label="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          class="login-field"
          :rules="[validations.required, validations.confirmPassword(model.password)]"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <SelectField
          outlined
          dense
          v-model="model.wayToKnow"
          :items="wayToKnows"
          prepend-icon="mdi-help-circle-outline"
          label="How did you hear about us"
          placeholder="How did you hear about us"
          class="login-field"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TeamMemberService from "../../services/TeamMemberService";
import UserService from "../../services/UserService";
import validations from "shared-components/src/utils/validations";
import store from "@/store";

export default defineComponent({
  props: {
    formId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      validations,
      wayToKnows: ["Search Engine", "Social Networks", "Email", "Job sites", "Word of mouth", "Code Clan website", "Referral", "Other"] as string[],
      model: {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        password: "",
        repassword: "",
        wayToKnow: "",
      },
    };
  },
  methods: {
    async submit() {
      const isValid = await (this.$refs.frmSignup as any).validate();
      if (isValid.valid) {
        this.$emit("submitting", true);

        try {
          const values = this.model;
          if (values.email) {
            values.email = values.email.toLowerCase().trim();
          }
          const userInfo = await UserService.signup(
            values.email,
            values.password,
            values.firstName,
            values.lastName,
            values.telephone,
            values.wayToKnow
          );
          const teamMemberInfo = await TeamMemberService.getTeammember(userInfo.id);
          store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
          store.dispatch("setUserInfo", { userInfo, vm: this });
          this.$router.push("/team-member/dashboard");
          // }
        } catch (ex: any) {
          store.dispatch("showErrorMessage", ex.message || ex);
        } finally {
          this.$emit("submitting", false);
        }
      }
    },
  },
});
</script>

<style scoped>
@media screen and (min-width: 800px) {
  .login-field {
    max-width: 600px;
  }
}
</style>
