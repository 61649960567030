import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "skillsDetailTab" }
const _hoisted_2 = { class: "wizard-action" }
const _hoisted_3 = { class: "d-flex ga-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_Skill = _resolveComponent("Skill")!
  const _component_LanguageSkillComponent = _resolveComponent("LanguageSkillComponent")!
  const _component_CertificateComponent = _resolveComponent("CertificateComponent")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_form, {
      ref: "frmMain",
      "lazy-validation": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-center text-md-left"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("h1", null, "Technical Skills", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_Skill, {
                  onShowLimitationMessage: _ctx.showLimitationMessage,
                  onSkillDownload: _ctx.skillDownload,
                  onSkillCertificateChanged: _ctx.skillCertificateChanged,
                  onSaveSkill: _ctx.saveSkill,
                  onRemove: _ctx.remove,
                  onShowEditSkill: _ctx.showEditSkill,
                  onSendAddRequest: _ctx.sendSkillAddRequest,
                  onCloseEditModal: _ctx.closeEditModal,
                  details: _ctx.details,
                  uploadedCertificate: _ctx.uploadedCertificate,
                  sendReqeustLoading: _ctx.skillLoading,
                  showEditSkillModal: _ctx.showEditSkillModal,
                  addSkillLoading: _ctx.skillLoading,
                  removeSkillloading: _ctx.skillLoading,
                  skills: _ctx.skills,
                  teammemberSkills: _ctx.teammemberSkills,
                  tmModel: _ctx.teamMember,
                  isGettingAllSection: _ctx.isGettingAllSection
                }, null, 8, ["onShowLimitationMessage", "onSkillDownload", "onSkillCertificateChanged", "onSaveSkill", "onRemove", "onShowEditSkill", "onSendAddRequest", "onCloseEditModal", "details", "uploadedCertificate", "sendReqeustLoading", "showEditSkillModal", "addSkillLoading", "removeSkillloading", "skills", "teammemberSkills", "tmModel", "isGettingAllSection"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-center text-md-left"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("h1", null, "Language Skills", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_LanguageSkillComponent, {
                  onSetLanguagesState: _ctx.setLanguagesState,
                  isAfterSignUp: _ctx.isAfterSignUp,
                  isGettingAllSection: _ctx.isGettingAllSection,
                  languageSkills: _ctx.languages
                }, null, 8, ["onSetLanguagesState", "isAfterSignUp", "isGettingAllSection", "languageSkills"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-center text-md-left"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("h1", null, "Certificates", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_CertificateComponent, {
                  skills: _ctx.skills,
                  teammemberSkills: _ctx.teammemberSkills,
                  isGettingAllSection: _ctx.isGettingAllSection,
                  isAfterSignUp: _ctx.isAfterSignUp,
                  certificates: _ctx.certificates,
                  onScrollToElement: _ctx.scrollToElement,
                  onGetCertificates: _ctx.getCertificates
                }, null, 8, ["skills", "teammemberSkills", "isGettingAllSection", "isAfterSignUp", "certificates", "onScrollToElement", "onGetCertificates"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.isAfterSignUp)
          ? (_openBlock(), _createBlock(_component_v_card_actions, {
              key: 0,
              class: "mt-5"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_tooltip, {
                    top: "",
                    attach: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ class: "secondary_btn" }, props, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getAllDataAndSubmit(true))),
                        disabled: _ctx.submitting,
                        loading: _ctx.submitting,
                        "prepend-icon": "mdi-check"
                      }), {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("Save and exit")
                        ])),
                        _: 2
                      }, 1040, ["disabled", "loading"])
                    ]),
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createElementVNode("span", null, "You can save your progress and return to the application process later", -1))
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_v_btn, {
                      class: "primary_btn actionButton",
                      onClick: _ctx.goBack,
                      disabled: _ctx.submitting,
                      loading: _ctx.submitting,
                      "prepend-icon": "mdi-chevron-left"
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Back")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled", "loading"]),
                    _createVNode(_component_v_btn, {
                      class: "primary_btn actionButton",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getAllDataAndSubmit(false))),
                      disabled: _ctx.submitting,
                      loading: _ctx.submitting,
                      "append-icon": "mdi-chevron-right"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Next")
                      ])),
                      _: 1
                    }, 8, ["disabled", "loading"])
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showSaveForLaterConfirm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showSaveForLaterConfirm) = $event)),
      "max-width": "500",
      onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.showSaveForLaterConfirm = false), ["esc"])),
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          loading: _ctx.savingLoading,
          message: _ctx.confirmModel,
          onConfirm: _ctx.saveForLator,
          onCancel: _ctx.cancelSaving
        }, null, 8, ["loading", "message", "onConfirm", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}