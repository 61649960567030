<template>
  <div class="profile-wizard">
    <v-stepper ref="mainRef" v-model="currentStep">
      <v-stepper-header>
        <div class="d-flex flex-column w-100">
          <div class="header-btns" :class="{ 'no-wizard': !isWizard }">
            <template v-for="(n, index) in steps" :key="`${n.step}-step`">
              <v-stepper-item
                :class="{ 'no-wizard': !isWizard }"
                :complete="currentStep > n.step && isWizard"
                complete-icon=""
                :step="n.step"
                :value="n.step"
                :editable="!isWizard"
              >
                <template v-slot:title>
                  <v-icon class="mr-2" v-if="!isWizard">{{ n.icon }}</v-icon
                  >{{ n.title }}
                </template>
                <template v-slot:subtitle v-if="isWizard">
                  <p class="mb-0"><v-icon class="mr-1">mdi-timer</v-icon>{{ n.description }}</p>
                </template>
              </v-stepper-item>
              <div class="step-divider" :class="{ 'no-wizard': !isWizard }" v-if="index != steps.length - 1"></div>
            </template>
          </div>
          <div class="step-progress">
            <v-progress-linear height="12" :model-value="wizardStep" background-color="#F7FFF7" v-if="isWizard" />
          </div>
        </div>
      </v-stepper-header>
      <v-stepper-window>
        <v-stepper-window-item :value="1">
          <PersonalDetail
            v-if="currentStep == 1"
            :loading="submitting"
            @submitPersonalDetail="submitPersonalDetail"
            @scrollToElement="scrollToElement"
            @getEducations="getEducations"
            :personalDetailModel="personalDetailModel"
            :isAfterSignUp="isWizard"
            :educations="educations"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="2">
          <ExperienceDetail
            @scrollToElement="scrollToElement"
            :formLoading="submitting"
            :professionalDetailModel="professionalDetailModel"
            :details="details"
            :loading="submitting"
            :teamMember="teamMemberModel"
            :sendReqeustLoading="sendReqeustLoading"
            :isAfterSignUp="isWizard"
            @submitExperienceDetail="submitExperienceDetail"
            @submitExperienceJobPreferences="submitExperienceJobPreferences"
            @removeJobPreferences="removeJobPreferences"
            @sendJobRequest="sendJobRequest"
            @goBack="prevStep"
            @showMessage="showMessage"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="3">
          <Education
            @goBack="prevStep"
            @goNext="nextStep"
            @scrollToElement="scrollToElement"
            @getEducations="getEducations"
            :isAfterSignUp="isWizard"
            :educations="educations"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="4">
          <Skills
            v-if="stepMounted.skill"
            @scrollToElement="scrollToElement"
            :formLoading="submitting"
            :details="details"
            :loading="submitting"
            :teamMember="teamMemberModel"
            :sendReqeustLoading="sendReqeustLoading"
            :isAfterSignUp="isWizard"
            :languages="languages"
            :skills="skills"
            :teammemberSkills="teammemberSkills"
            :uploadedCertificate="uploadedCertificate"
            :showEditSkillModal="showEditSkillModal"
            :skillLoading="skillLoading"
            :certificates="certificates"
            @submitSkillsDetail="submitSkillsDetail"
            @goBack="prevStep"
            @showLimitationMessage="showLimitationMessage"
            @skillDownload="skillDownload"
            @skillCertificateChanged="skillCertificateChanged"
            @saveSkill="saveSkill"
            @remove="remove"
            @showEditSkill="showEditSkill"
            @sendAddRequest="sendSkillAddRequest"
            @closeEditModal="closeEditModal"
            @getCertificates="getCertificates"
            @setLanguagesState="setLanguagesState"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="5">
          <CareerHistory
            v-if="stepMounted.career"
            @goNext="nextStep"
            @scrollToElement="scrollToElement"
            @sendJobRequest="sendJobRequest"
            @goBack="prevStep"
            @getCareerHistories="getCareerHistories"
            @setCareerHistoryDescriptionState="setCareerHistoryDescriptionState"
            @updateJobTitle="updateJobTitle"
            @updateSkill="updateSkillList"
            :formLoading="submitting"
            :isAfterSignUp="isWizard"
            :skills="skills"
            :careerHistories="careerHistories"
            :careerHistoryDescription="careerHistoryDescription"
            :teammemberSkills="teammemberSkills"
            :submitting="submitting"
            :details="details"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="6">
          <ProfilePhoto
            :loading="submitting"
            @scrollToElement="scrollToElement"
            @submitForm="submitPersonalDetail"
            @goBack="prevStep"
            :teammemberModel="teamMemberModel"
            :isAfterSignUp="isWizard"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="7">
          <ContactComponent
            :addressList="addresses"
            :phoneList="phoneNumbers"
            :addressSendCodeLoading="addressSendCodeLoading"
            :phoneSendCodeLoading="phoneSendCodeLoading"
            :addressLoading="addressLoading"
            :phoneNumberLoading="phoneNumberLoading"
            :addressVerifyLoading="addressVerifyLoading"
            :phoneNumberVerifyLoading="phoneNumberVerifyLoading"
            :tmModel="teamMemberModel"
            :formLoading="submitting"
            @getContact="getContact"
            @setAddressAsDefault="setAddressAsDefault"
            @setPhoneAsDefault="setPhoneAsDefault"
            @verifyAddress="verifyAddress"
            @verifyPhone="verifyPhone"
            @sendAddressCode="sendAddressCode"
            @sendPhoneCode="sendPhoneCode"
            @removeAddress="removeAddress"
            @scrollToElement="scrollToElement"
            @removePhone="removePhone"
            @saveAddress="saveAddress"
            @savePhone="savePhone"
          />
        </v-stepper-window-item>
      </v-stepper-window>
      <v-dialog v-if="showConfirm" v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false" persistent>
        <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
      </v-dialog>
      <v-dialog v-if="showJobRequestConfirm" v-model="showJobRequestConfirm" max-width="500" @keydown.esc="showJobRequestConfirm = false" persistent>
        <confirm-dialog
          :loading="sendReqeustLoading"
          :message="confirmModel"
          showInput
          inputLable="Job Title"
          @confirm="confirmSendJobRequest"
          @cancel="cancelSendRequest"
        ></confirm-dialog>
      </v-dialog>
      <v-dialog
        v-if="showSkillRequestConfirm"
        v-model="showSkillRequestConfirm"
        max-width="500"
        @keydown.esc="showSkillRequestConfirm = false"
        persistent
      >
        <confirm-dialog
          :loading="sendReqeustLoading"
          :message="confirmModel"
          showInput
          inputLable="Skill"
          @confirm="sendAddSkillRequest"
          @cancel="cancelSendRequest"
        ></confirm-dialog>
      </v-dialog>
    </v-stepper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UserInfo from "shared-components/src/models/UserInfo";
import { mapMutations } from "vuex";
import { SHOW_LOADING, HIDDEN_LOADING } from "@/store/types";
import {
  Certificate,
  Education as EducationModel,
  LanguageSkill,
  ProfessionalDetail,
  Teammember,
  TeammemberSkill,
  Address,
  Phone,
} from "shared-components/src/models/Teammember";
import Education from "shared-components/src/components/Profile/education/Education.vue";
import CareerHistoryService from "shared-components/src/services/CareerHistoryService";
import CoreSkillService from "@/services/CoreSkillService";
import TeamMemberService from "@/services/TeamMemberService";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import CoreSkill from "shared-components/src/models/CoreSkill";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import PersonalDetail from "shared-components/src/components/Profile/PersonalDetail.vue";
import Skills from "./completeProfile/Skills.vue";
import CareerHistory from "shared-components/src/components/Profile/careerHistory/CareerHistory.vue";
import ProfilePhoto from "./completeProfile/ProfilePhoto.vue";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";
import DetailsService from "@/services/DetailsService";
import ExperienceDetail from "shared-components/src/components/Profile/ExperienceDetail.vue";
import Details from "shared-components/src/models/Details";
import CareerHistories from "shared-components/src/models/CareerHistories";
import store from "@/store";
import ContactComponent from "shared-components/src/components/Profile/Contact.vue";

export default defineComponent({
  props: {
    isWizard: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ConfirmDialog,
    PersonalDetail,
    ExperienceDetail,
    CareerHistory,
    ProfilePhoto,
    Skills,
    Education,
    ContactComponent,
  },
  data() {
    return {
      showSkillRequestConfirm: false,
      showJobRequestConfirm: false,
      uploadedCertificate: "",
      careerHistorySummary: "",
      teamMemberModel: null as Teammember | null,
      teammemberSkills: [] as TeammemberSkill[],
      skills: [] as CoreSkill[],
      selectedSkill: {} as TeammemberSkill,
      details: [] as Details[],
      personalDetailModel: {} as PersonalDetailModel,
      showEditSkillModal: false,
      skillLoading: false,
      careerHistoryDescription: "",
      selectedJob: "",
      professionalDetailModel: {} as ProfessionalDetail | null,
      careerHistories: [] as CareerHistories[],
      languages: [] as LanguageSkill[] | null,
      certificates: [] as Certificate[],
      educations: [] as EducationModel[],
      showSaveForLaterConfirm: false,
      savingLoading: false,
      showConfirm: false,
      submitting: false,
      selectedSkillTitle: "",
      isFirstTimeLoad: true,
      sendReqeustLoading: false,
      confirmModel: {
        title: "",
        text: "",
      },
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      currentStep: 1,
      steps: [
        { step: 1, value: "personalDetails", title: "Personal Details", description: "1 MIN", icon: "mdi-account-box" },
        { step: 2, value: "jobPreferences", title: "Job Preferences", description: "3 MIN", icon: "mdi-account-details" },
        { step: 3, value: "education", title: "Education", description: "5 MIN", icon: "mdi-school" },
        { step: 4, value: "skillsAndCertificates", title: "Skills and Certificates", description: "3 MIN", icon: "mdi-certificate" },
        { step: 5, value: "careerHistory", title: "Career History", description: "3 MIN", icon: "mdi-table-chair" },
        { step: 6, value: "profile", title: "Profile Setup", description: "5 MIN", icon: "mdi-account-tie" },
      ],
      stepMounted: {
        career: false,
        education: false,
        skill: false,
      },
      addresses: [] as Address[],
      phoneNumbers: [] as Phone[],
      phoneNumberLoading: false,
      addressLoading: false,
      phoneSendCodeLoading: false,
      addressSendCodeLoading: false,
      phoneNumberVerifyLoading: false,
      addressVerifyLoading: false,
    };
  },
  created() {
    this.SHOW_LOADING();
  },
  async mounted() {
    await this.loadDetailsList();
    await this.fetchTeammemberInfo();
    await this.loadSkillsList();
    this.isFirstTimeLoad = false;
    this.setMountedValues();
    this.HIDDEN_LOADING();
    if (!this.isWizard) {
      this.steps.push({ step: 7, value: "contact", title: "Contact", description: "5 MIN", icon: "mdi-phone" });
    }
  },
  methods: {
    async setAddressAsDefault(addressId: any) {
      this.addressLoading = true;
      var addresses = await TeamMemberService.setAddressAsDefault(addressId);
      if (addresses && addresses.length > 0) {
        this.addresses = addresses;
        store.dispatch("showSuccessMessage", ProjectMessages.addressSaved);
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.error);
      }
      this.addressLoading = false;
    },
    async setPhoneAsDefault(phoneId: any) {
      this.phoneNumberLoading = true;
      var phonenumbers = await TeamMemberService.setPhoneAsDefault(phoneId);
      if (phonenumbers && phonenumbers.length > 0) {
        this.phoneNumbers = phonenumbers;
        store.dispatch("showSuccessMessage", ProjectMessages.phoneNumberSaved);
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.error);
      }
      this.phoneNumberLoading = false;
    },
    async verifyAddress(address: any) {
      this.addressVerifyLoading = true;
      if (this.teamMemberModel) {
        var addresses = await TeamMemberService.verifyAddress(address);
        if (addresses && addresses.length > 0) {
          var savedItem = addresses.find((c) => c.Id === address.Id) as Address;
          var existAddress = this.addresses.find((c) => c.Id === address.Id);
          if (existAddress && existAddress.WrongCodeInputCount >= 5) {
            store.dispatch("showErrorMessage", ProjectMessages.extraWrongVerifyRequest);
          } else if (existAddress && existAddress.WrongCodeInputCount != savedItem.WrongCodeInputCount) {
            store.dispatch("showErrorMessage", ProjectMessages.errorVerificationAlert);
          } else {
            store.dispatch("showSuccessMessage", ProjectMessages.successAddressVerificationAlert);
          }
          this.addresses = addresses;
        }
      }
      this.addressVerifyLoading = false;
    },
    async verifyPhone(phone: any) {
      this.phoneNumberVerifyLoading = true;
      if (this.teamMemberModel) {
        var phonenumberList = await TeamMemberService.verifyPhone(phone);
        if (phonenumberList && phonenumberList.length > 0) {
          var savedItem = phonenumberList.find((c) => c.Id === phone.Id) as Phone;
          var existItem = this.phoneNumbers.find((c) => c.Id === phone.Id);
          if (existItem && existItem.WrongCodeInputCount >= 5) {
            store.dispatch("showErrorMessage", ProjectMessages.extraWrongVerifyRequest);
          } else if (existItem && existItem.WrongCodeInputCount != savedItem.WrongCodeInputCount) {
            store.dispatch("showErrorMessage", ProjectMessages.errorVerificationAlert);
          } else {
            store.dispatch("showSuccessMessage", ProjectMessages.successPhoneNumberVerificationAlert);
          }
          this.phoneNumbers = phonenumberList;
        }
      }

      this.phoneNumberVerifyLoading = false;
    },
    async sendAddressCode(id: any) {
      this.addressSendCodeLoading = true;
      if (this.teamMemberModel) {
        var addresses = await TeamMemberService.sendAddressCode(id);
        this.addressSendCodeLoading = false;
        if (addresses && addresses.length > 0) {
          this.addresses = addresses;
        }
      }
    },
    async sendPhoneCode(id: any) {
      this.phoneSendCodeLoading = true;
      if (this.teamMemberModel) {
        var phoneNumbers = await TeamMemberService.sendPhoneCode(id);
        this.phoneSendCodeLoading = false;
        if (phoneNumbers && phoneNumbers.length > 0) {
          this.phoneNumbers = phoneNumbers;
        }
      }
    },
    async removeAddress(addressId: any) {
      this.addressLoading = true;
      if (this.teamMemberModel) {
        var addresses = await TeamMemberService.removeAddress(addressId);
        if (addresses && addresses.length > 0) {
          this.addresses = addresses;
          store.dispatch("showSuccessMessage", ProjectMessages.removeAddress);
        } else {
          store.dispatch("showErrorMessage", ProjectMessages.error);
          this.addresses = [];
        }
      }
      this.addressLoading = false;
    },
    async removePhone(phoneId: any) {
      this.phoneNumberLoading = true;
      if (this.teamMemberModel) {
        var phoneNumbers = await TeamMemberService.removePhone(phoneId);
        if (phoneNumbers && phoneNumbers.length > 0) {
          this.phoneNumbers = phoneNumbers;
          store.dispatch("showSuccessMessage", ProjectMessages.removePhone);
        } else {
          this.phoneNumbers = [];
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.error);
      }
      this.phoneNumberLoading = false;
    },
    async saveAddress(address: Address) {
      this.addressLoading = true;
      if (this.teamMemberModel) {
        var addressList = [...this.addresses];
        var addressList = await TeamMemberService.saveAddress(address);
        if (addressList && addressList.length > 0) {
          this.addresses = addressList;
          store.dispatch("showSuccessMessage", ProjectMessages.saveAddress);
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.error);
      }
      this.addressLoading = false;
    },
    async savePhone(phoneNumber: any) {
      this.phoneNumberLoading = true;
      if (this.teamMemberModel) {
        var phoneNumbers = await TeamMemberService.savePhone(phoneNumber);
        if (phoneNumbers && phoneNumbers.length > 0) {
          this.phoneNumbers = phoneNumbers;
          store.dispatch("showSuccessMessage", ProjectMessages.savePhoneNumber);
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.error);
      }
      this.phoneNumberLoading = false;
    },
    async getContact() {
      this.submitting = true;
      var result = await TeamMemberService.getContact();
      if (result) {
        if (result.Addresses) {
          this.addresses = result.Addresses;
        } else {
          this.addresses = [];
        }
        if (result.PhoneNumbers) {
          this.phoneNumbers = result.PhoneNumbers;
        } else {
          this.phoneNumbers = [];
        }
      }
      this.submitting = false;
    },
    setCareerHistoryDescriptionState(careerHistoryDescription: any) {
      if (this.teamMemberModel) {
        this.teamMemberModel.CareerHistoryDescription = careerHistoryDescription;
        this.setTeamMemberInfo(this.teamMemberModel);
      }
    },
    async getCertificates() {
      this.SHOW_LOADING();
      var result = await TeamMemberService.getCertificates();
      if (result) {
        this.certificates = result;
      }
      this.HIDDEN_LOADING();
    },
    async getEducations() {
      this.SHOW_LOADING();
      var result = await TeamMemberService.getEducations();
      if (result) {
        this.educations = result;
      }
      this.HIDDEN_LOADING();
    },
    async getCareerHistories() {
      this.SHOW_LOADING();
      var result = await CareerHistoryService.getCareerHistories();
      if (result) {
        this.careerHistories = result;
      }
      this.HIDDEN_LOADING();
    },
    async sendSkillAddRequest(skillName: any) {
      this.confirmModel.title = `Sending request to add new skill`;
      this.confirmModel.text = `Are you sure you want to send request to admin to add this?`;
      this.showSkillRequestConfirm = true;
      this.selectedSkillTitle = skillName;
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    async removeJobPreferences(event: { props: any; callbackMethod: any }) {
      this.submitting = true;

      if (event.props.index != null) {
        if (this.professionalDetailModel) {
          this.teamMemberModel?.JobPreferences?.splice(event.props.index, 1);
        }
      } else {
        var result = await TeamMemberService.removeExperienceJobPreferences(event.props.id);
        if (result) {
          if (this.teamMemberModel) {
            this.teamMemberModel.JobPreferences?.splice(
              this.teamMemberModel.JobPreferences?.findIndex((item) => item.Id == event.props.id),
              1
            );
          }
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyRemoveExperienceJobPreferences);
        } else {
          store.dispatch("showErrorMessage", ProjectMessages.errorRemoveExperienceJobPreferences);
        }
      }
      if (this.teamMemberModel) {
        this.teamMemberModel.ProfessionalDetail = this.professionalDetailModel;
        event.callbackMethod();
        this.setTeamMemberInfo(this.teamMemberModel);
      }
      this.submitting = false;
    },
    async submitExperienceJobPreferences(event: { props: any; callbackMethod: any }) {
      this.submitting = true;
      if (this.teamMemberModel) {
        var findObjective = this.teamMemberModel.JobPreferences?.find(
          (c) => c.WorkingPosition === event.props.model.WorkingPosition && (!event.props.model.Id || event.props.model.Id != c.Id)
        );
        if (findObjective) {
          store.dispatch("showErrorMessage", ProjectMessages.ErrorDuplicateJobPreferences);
        } else {
          if (event.props.model) {
            var result = await TeamMemberService.saveExperienceJobPreferences(event.props.model);
            if (result != null && result.Id) {
              if (!this.teamMemberModel.JobPreferences) {
                this.teamMemberModel.JobPreferences = [];
              }
              if (this.teamMemberModel) {
                let existingItemIndex = this.teamMemberModel.JobPreferences?.findIndex((item) => item.Id == (result as any).Id);
                if (existingItemIndex == -1) {
                  this.teamMemberModel.JobPreferences.push(result);
                } else {
                  this.teamMemberModel.JobPreferences[existingItemIndex] = result;
                }
                event.callbackMethod(result);
              }
              store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceJobPreferences);
            } else if (result == null) {
              store.dispatch("showErrorMessage", ProjectMessages.errorAddExperienceJobPreferences);
            } else {
              store.dispatch("showErrorMessage", result);
            }
          }
          // } else {
          //   if (!this.professionalDetailModel) {
          //     this.professionalDetailModel = {} as ProfessionalDetail;
          //   }
          //   if (!this.professionalDetailModel.CareerObjectives) {
          //     this.professionalDetailModel.CareerObjectives = [];
          //   }
          //   this.SaveJobPreferencess(event.props.model, event.props.index);
          //   this.teamMemberModel.ProfessionalDetail = this.professionalDetailModel;
          //   event.callbackMethod();
          //   store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceJobPreferences);
          // }
        }

        this.setTeamMemberInfo(this.teamMemberModel);
      }
      this.submitting = false;
    },
    updateJobTitle(item: Details) {
      var detailList = [...this.details];
      detailList.push(item);
      this.details = detailList;
    },
    async submitExperienceDetail(model: any) {
      this.submitting = true;
      if (model.tm.JobTitleId) {
        var jobtitle = this.details.find(
          (c) => c.Type == "JobTitle" && (c.id === model.tm.JobTitleId || c.Name.trim().toLowerCase() === model.tm.JobTitleId.trim().toLowerCase())
        );
        if (jobtitle) {
          model.tm.JobTitleId = jobtitle.id;
        }
      }
      var result = await TeamMemberService.saveExperienceDetail(model.tm);
      if (result) {
        if (this.teamMemberModel) {
          this.teamMemberModel.ProfessionalDetail = model.tm;
          this.teamMemberModel.SpecificRoles = model.tm.JobRole;
          if (this.teamMemberModel.ProfessionalDetail) {
            this.teamMemberModel.ProfessionalDetail.Id = result.Id;
          }
          if (this.professionalDetailModel) {
            this.professionalDetailModel.Id = result.Id;
          }
          if (!jobtitle) {
            this.updateJobTitle({ id: result.JobTitleId, Name: model.tm.Job, RoleId: model.tm.JobRole, Type: "JobTitle" } as Details);
          }
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        if (model.step) {
          this.nextStep(model.step);
        } else {
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceDetail);
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.errorAddExperienceDetail);
      }
      this.submitting = false;
    },
    async submitSkillsDetail(model: any) {
      this.submitting = true;
      if (model.step) {
        this.nextStep(model.step);
      } else {
        store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceDetail);
      }
      this.submitting = false;
    },
    async submitPersonalDetail(model: any) {
      this.submitting = true;
      var result = await TeamMemberService.savePersonalDetail(model.tm);
      if (result && result.Id) {
        this.personalDetailModel = result;
        if (this.teamMemberModel) {
          this.teamMemberModel.BirthDate = result.BirthDate;
          this.teamMemberModel.Country = result.Country;
          this.teamMemberModel.City = result.City;
          this.teamMemberModel.CV = result.CV;
          this.teamMemberModel.Description = result.Description;
          this.teamMemberModel.Email = result.Email;
          this.teamMemberModel.FirstName = result.FirstName;
          this.teamMemberModel.GitHub = result.GitHub;
          this.teamMemberModel.LastName = result.LastName;
          this.teamMemberModel.LinkedIn = result.LinkedIn;
          this.teamMemberModel.MaritalStatus = result.MaritalStatus;
          this.teamMemberModel.MilitaryServiceSituation = result.MilitaryServiceSituation;
          this.teamMemberModel.Mobile = result.Mobile;
          this.teamMemberModel.Nationality = result.Nationality;
          this.teamMemberModel.NickName = result.NickName;
          this.teamMemberModel.Phone = result.Phone;
          this.teamMemberModel.PhotoUrl = result.PhotoUrl;
          this.teamMemberModel.SecondaryEmail = result.SecondaryEmail;
          this.teamMemberModel.StillStudying = result.StillStudying;
          this.teamMemberModel.WebSite = result.WebSite;
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        if (model.personality && this.teamMemberModel?.ProfessionalDetail) {
          this.teamMemberModel.ProfessionalDetail.Personality = model.personality;
          await TeamMemberService.saveExperienceDetail(this.teamMemberModel.ProfessionalDetail);
        }
        if (this.isWizard) {
          if (model.isLastStep) {
            if (!model.isForLator) {
              this.isProfileCompleted();
            } else {
              store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddProfileDetails);
            }
          } else if (model.step) {
            this.nextStep(model.step);
          } else {
            store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddPersonalDetails);
          }
        } else {
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddPersonalDetails);
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.errorAddPersonalDetails);
      }
      this.submitting = false;
    },

    async skillCertificateChanged(skill: any) {
      if (this.teamMemberModel) {
        this.uploadedCertificate = await TeamMemberService.uploadSkillCv(skill.skillId, skill.file);
      }
    },
    async skillDownload(certificateUrl: any) {
      if (this.teamMemberModel) {
        const downloadLink = await TeamMemberService.getCertificateDownloadLink(certificateUrl || "");
        window.open(downloadLink, "_blank");
      }
    },
    showLimitationMessage(text: any) {
      store.dispatch("showSuccessMessage", text);
    },
    async saveTeammember(data: any) {
      this.submitting = true;
      var result = await TeamMemberService.create(data.tm);
      if (result) {
        this.teamMemberModel = data.tm;
        if (this.teamMemberModel) {
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        if (data.step) {
          this.nextStep(data.step);
        }
        if (data.isLastStep) {
          this.isProfileCompleted();
        }
      } else {
        store.dispatch("showErrorMessage", "Couldn't save Professional details!");
      }
      this.submitting = false;
    },
    async fetchTeammemberInfo() {
      var step = 0;
      try {
        if (!this.userInfo || !this.userInfo.id) {
          throw Error("Please login!");
        } else {
          this.teamMemberModel = await TeamMemberService.GetTeammemberWithProfessional();
        }
      } catch (teammemberError) {
        store.dispatch("showErrorMessage", "There is some error to get your team member inforamtion!");
      }
      if (this.teamMemberModel) {
        if (!this.teamMemberModel.FirstName) {
          this.teamMemberModel.FirstName = this.userInfo.name;
        }
        if (!this.teamMemberModel.LastName) {
          this.teamMemberModel.LastName = this.userInfo.surname;
        }
        if (!this.teamMemberModel.Phone) {
          this.teamMemberModel.Phone = this.userInfo.phoneNumber;
        }
        if (!this.teamMemberModel.Email) {
          this.teamMemberModel.Email = this.userInfo.userName || "";
        }
        if (!this.teamMemberModel.MemberSince) {
          this.teamMemberModel.MemberSince = this.userInfo.creationDate;
        }
        this.teammemberSkills = this.teamMemberModel.Skills ? this.teamMemberModel.Skills : [];
        if (!this.teamMemberModel.SecondaryEmail) {
          this.teamMemberModel.SecondaryEmail = this.teamMemberModel.Email;
        }
        this.personalDetailModel = {
          Id: this.teamMemberModel.Id,
          BirthDate: this.teamMemberModel.BirthDate,
          Country: this.teamMemberModel.Country,
          City: this.teamMemberModel.City,
          CV: this.teamMemberModel.CV,
          Description: this.teamMemberModel.Description,
          Email: this.teamMemberModel.Email,
          FirstName: this.teamMemberModel.FirstName,
          GitHub: this.teamMemberModel.GitHub,
          LastName: this.teamMemberModel.LastName,
          LinkedIn: this.teamMemberModel.LinkedIn,
          MaritalStatus: this.teamMemberModel.MaritalStatus,
          MilitaryServiceSituation: this.teamMemberModel.MilitaryServiceSituation,
          Mobile: this.teamMemberModel.Mobile,
          Nationality: this.teamMemberModel.Nationality,
          NickName: this.teamMemberModel.NickName,
          Phone: this.teamMemberModel.Phone,
          PhotoUrl: this.teamMemberModel.PhotoUrl,
          SecondaryEmail: this.teamMemberModel.SecondaryEmail,
          StillStudying: this.teamMemberModel.StillStudying,
          WebSite: this.teamMemberModel.WebSite,
        };
        this.careerHistoryDescription = this.teamMemberModel.CareerHistoryDescription;
        this.languages = this.teamMemberModel.LanguageSkills;
        if (this.teamMemberModel.SpecificRoles && this.teamMemberModel.ProfessionalDetail) {
          this.teamMemberModel.ProfessionalDetail.JobRole = this.teamMemberModel.SpecificRoles;
        }

        this.professionalDetailModel = this.teamMemberModel.ProfessionalDetail;
        if (!this.professionalDetailModel) {
          this.professionalDetailModel = {} as ProfessionalDetail;
        } else {
          // if (store.state.careerObjectives) {
          //   var objectivesJobTitleId = this.professionalDetailModel?.CareerObjectives.map((c: any) => c.WorkingPosition);
          //   const uniqueCareerObjectives = store.state.careerObjectives.filter((c: any) => !objectivesJobTitleId.includes(c.WorkingPosition));
          //   this.setCareerObjectiveState(uniqueCareerObjectives);
          //   this.professionalDetailModel?.CareerObjectives.push.apply(this.professionalDetailModel.CareerObjectives, store.state.careerObjectives);
          //   this.setCareerObjectiveState(this.professionalDetailModel?.CareerObjectives);
          // }
        }
        if (this.isWizard) {
          if (!this.hasPersonalDetail(this.teamMemberModel)) {
            step = 0;
          } else if (!this.hasProfessionalDetails(this.teamMemberModel)) {
            step = 1;
          } else if (!this.hasCareerHistory(this.teamMemberModel)) {
            step = 4;
          } else if (!this.hasProfileDetail(this.teamMemberModel)) {
            step = 5;
          }
        }

        if (step != 0) {
          this.nextStep(step);
        }
      }
    },
    areObjectsEqual(obj1: any, obj2: any) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    hasPersonalDetail(tm: Teammember) {
      return tm.FirstName && tm.LastName && tm.BirthDate && tm.Phone && tm.Email && tm.Nationality && tm.Country && tm.City;
    },
    hasProfessionalDetails(tm: Teammember) {
      return (
        tm.ProfessionalDetail &&
        tm.ProfessionalDetail.JobTitleId &&
        tm.SpecificRoles &&
        tm.SpecificRoles.length > 0 &&
        tm.JobPreferences &&
        tm.JobPreferences.length > 0 &&
        tm.ProfessionalDetail.ExperienceYear &&
        tm.ProfessionalDetail.ExperienceYear != "0" &&
        tm.ProfessionalDetail.Personality &&
        tm.ProfessionalDetail.Personality.length >= 250
      );
    },
    hasCareerHistory(tm: any) {
      return tm.CareerHistoryDescription && tm.CareerHistoryDescription.length >= 250 ? true : false;
    },
    hasProfileDetail(tm: any) {
      return tm.Description && tm.Description.lengh >= 250 && tm.PhotoUrl && tm.CV;
    },
    isProfileCompleted() {
      store.dispatch("showSuccessMessage", "Congratulations! You completed your profile successfuly. thanks for joining our team!");
      this.$router.push("/teamMember/dashboard");
    },
    ...mapMutations([SHOW_LOADING, HIDDEN_LOADING]),
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    async remove(item: TeammemberSkill) {
      var findSkill = this.skills.find((c) => c.id === item.SkillId);
      if (findSkill) {
        this.confirmModel.title = `Remove Skill(${findSkill.Text})`;
        this.confirmModel.text = "Are you sure?";
        this.selectedSkill = item;
        this.showConfirm = true;
      }
    },
    async sendAddSkillRequest(skill: any) {
      this.sendReqeustLoading = true;
      var skillTilteExist = this.skills.some((item) => item.Text && item.Text.toLowerCase().trim() === skill.toLowerCase().trim());
      if (skillTilteExist) {
        store.dispatch("showErrorMessage", ProjectMessages.DuplicateSkillTitle);
      }
      this.sendReqeustLoading = false;
      this.showSkillRequestConfirm = false;
    },
    cancelSendRequest() {
      this.showJobRequestConfirm = false;
      this.showSkillRequestConfirm = false;
      this.selectedSkillTitle = "";
      this.selectedJob = "";
    },
    setLanguagesState(items: any) {
      if (this.teamMemberModel) {
        this.teamMemberModel.LanguageSkills = items;
        this.setTeamMemberInfo(this.teamMemberModel);
      }
    },
    sendJobRequest(job: any) {
      this.confirmModel.title = "Sending request to add a Job Title";
      this.confirmModel.text = "Are you sure you want to send request to admin to add This?";
      this.showJobRequestConfirm = true;
    },
    async confirmSendJobRequest(job: any) {
      var jobTilteExist = this.details.some(
        (item) => item.Name && job && item.Type == "JobTitle" && item.Name.toLowerCase().trim() === job.toLowerCase().trim()
      );
      if (jobTilteExist) {
        store.dispatch("showErrorMessage", ProjectMessages.DuplicateJobTitle);
      }
    },
    showMessage(model: any) {
      store.dispatch(model.type, model.msg);
    },
    async confirmRemove(): Promise<void> {
      this.showConfirm = false;
      this.skillLoading = true;
      if (this.selectedSkill) {
        const result = await TeamMemberService.removeSkill(this.selectedSkill.SkillId);
        if (result) {
          this.teammemberSkills = result;
          if (this.teamMemberModel) {
            this.teamMemberModel.Skills = result;
            this.setTeamMemberInfo(this.teamMemberModel);
          }
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyRemoveSkill);
        } else {
          store.dispatch("showErrorMessage", ProjectMessages.errorRemoveSkill);
        }
      }
      this.skillLoading = false;
    },
    cancelRemove(): void {
      this.showConfirm = false;
      this.selectedSkill = {} as TeammemberSkill;
      (this.selectedJob = ""), (this.showJobRequestConfirm = false);
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    updateSkillList(newVal: any) {
      var skillDatas = [...this.skills];
      var mappedSkills = newVal.newSkills.map((c: any) => {
        return { Text: c.Text, id: c.id };
      });
      skillDatas.push(...mappedSkills);
      this.skills = skillDatas;
    },
    async saveSkill(skillModel: TeammemberSkill) {
      this.skillLoading = true;
      var findSkill = this.skills.find((c) => c.id === skillModel.SkillId || c.Text.trim().toLowerCase() === skillModel.SkillId.trim().toLowerCase());
      if (findSkill) {
        skillModel.SkillId = skillModel.SkillId;
      }
      try {
        var result = await TeamMemberService.updateSkill(skillModel);
        if (result && result.length > 0) {
          if (this.teamMemberModel) {
            if (!findSkill) {
              var skillData = [...this.skills];
              var mappedSkills = this.skills.map((c) => c.id);
              var savedSkill = result.find((c) => !mappedSkills.includes(c.SkillId));
              if (savedSkill) {
                skillData.push({ id: savedSkill.SkillId, Text: skillModel.SkillId } as CoreSkill);
                this.skills = skillData;
              }
            }
            this.teamMemberModel.Skills = result;
            this.setTeamMemberInfo(this.teamMemberModel);
          }
          this.teammemberSkills = result;
          if (skillModel.Id) {
            store.dispatch("showSuccessMessage", ProjectMessages.succesfullyUpdatedSkill);
          } else {
            store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddSkill);
          }
        } else {
          store.dispatch("showErrorMessage", ProjectMessages.errorAddSkill);
        }

        this.closeEditModal();
      } finally {
        this.skillLoading = false;
      }
    },
    async sendAddRequest() {
      this.sendReqeustLoading = true;
      this.sendReqeustLoading = false;
      this.showSkillRequestConfirm = false;
    },
    closeEditModal() {
      this.showEditSkillModal = false;
    },
    showEditSkill() {
      this.showEditSkillModal = true;
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    async scrollToElement(el: any) {
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    },
    nextStep(n: any) {
      if (n === this.steps.length) {
        this.currentStep = 1;
      } else {
        this.currentStep = n + 1;
      }
      this.setMountedValues();
      var el = document.querySelector("#main-body");
      if (el) {
        el.scrollIntoView(true);
      }
    },
    prevStep(stepNumber: any) {
      stepNumber = stepNumber - 1;
      this.nextStep(stepNumber);
    },
    setMountedValues() {
      switch (this.currentStep) {
        case 1:
          this.stepMounted.education = true;
        case 4:
          this.stepMounted.skill = true;
        case 5:
          this.stepMounted.career = true;
      }
    },
  },
  computed: {
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
    wizardStep(): number {
      return (100 / this.steps.length) * this.currentStep;
    },
  },
});
</script>
<style lang="scss">
@import "node_modules/shared-components/assets/style/font.scss";

.v-application--wrap {
  overflow-y: scroll;
}

.profile-wizard {
  .wizard-action {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button {
      min-width: 234px;
    }
  }

  .v-stepper {
    background: transparent;

    .v-stepper-header {
      justify-content: unset !important;
      align-items: center !important;
      height: auto;
      overflow: hidden;

      .header-btns {
        display: flex;
        align-items: center;
        overflow: auto;
      }

      .no-wizard {
        .v-stepper-item__avatar {
          display: none;
        }
      }
      button.v-stepper-item {
        padding: 12px 7px;
        .v-stepper-item__avatar {
          width: 32px !important;
          height: 32px !important;
          @extend .f-body-2;
          color: $c_dark;
        }
        .v-stepper-item__content {
          color: $c_white;

          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
      button.v-stepper-item:not(.v-stepper-item--selected, .v-stepper-item--complete) {
        .v-stepper-item__avatar {
          color: $c_white;
          background-color: $c_jet_gray !important;
        }
      }

      .v-stepper-item--complete {
        .v-stepper-item__avatar {
          background-color: $c_celadon !important;
        }
      }

      .v-stepper-item--selected {
        background-color: $c_jet_gray;
        @extend .b-r-10;
        .v-stepper-item__avatar {
          background-color: $c_platinum !important;
          color: $c_dark !important;
        }
      }

      .v-stepper-item {
        padding: 10px;
        padding-bottom: 0px;
      }

      .step-divider {
        width: 15px;
        height: 15px;
        border: solid $c_white;
        border-width: 0 3px 3px 0;
        display: block;
        padding: 6px;
        margin-right: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);

        &.no-wizard {
          width: 2px !important;
          border-width: 0 1px 0 0;
          transform: none;
        }
      }

      .step-progress {
        margin: 15px 5px;

        .v-progress-linear {
          @extend .b-r-10;
          width: 100%;
          background: $c_mint_creme;

          .v-progress-linear__determinate {
            background-color: $c_celadon;
          }
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .profile-wizard {
    .item-card-icons {
      width: 30px;
    }
    .v-stepper {
      .v-stepper-header {
        .header-btns:not(.no-wizard) {
          justify-content: center;
        }

        button.v-stepper-item:not(.v-stepper-item--selected, .no-wizard) {
          display: none !important;
        }
        .step-divider:not(.no-wizard) {
          display: none !important;
        }
      }
    }

    .wizard-action {
      flex-direction: column-reverse !important;
      button {
        min-width: auto !important;
      }
    }
  }
}
</style>
