import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "frmSignup",
    id: _ctx.formId,
    onSubmit: _withModifiers(_ctx.submit, ["prevent"]),
    class: "form-inputs"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                modelValue: _ctx.model.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.firstName) = $event)),
                dense: "",
                "prepend-icon": "mdi-account",
                label: "First Name",
                placeholder: "First Name",
                type: "text",
                class: "login-field",
                outlined: "",
                rules: [_ctx.validations.required]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                dense: "",
                modelValue: _ctx.model.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.lastName) = $event)),
                "prepend-icon": "mdi-account-box",
                label: "Last Name",
                placeholder: "Last Name",
                type: "text",
                class: "login-field",
                outlined: "",
                rules: [_ctx.validations.required]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                dense: "",
                modelValue: _ctx.model.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.email) = $event)),
                "prepend-icon": "mdi-email",
                label: "Email",
                placeholder: "Email",
                type: "text",
                class: "login-field",
                rules: [_ctx.validations.required, _ctx.validations.EmailCheck],
                outlined: ""
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                dense: "",
                modelValue: _ctx.model.telephone,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.telephone) = $event)),
                "prepend-icon": "mdi-phone",
                label: "Phone Number",
                placeholder: "Phone Number",
                type: "text",
                class: "login-field",
                rules: [_ctx.validations.required, _ctx.validations.phoneNumber],
                outlined: ""
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                dense: "",
                autocomplete: "new-password",
                modelValue: _ctx.model.password,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.password) = $event)),
                "prepend-icon": "mdi-key",
                label: "Password",
                placeholder: "Password",
                type: "password",
                class: "login-field",
                rules: [_ctx.validations.required],
                outlined: ""
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            md: "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                dense: "",
                autocomplete: "new-password",
                modelValue: _ctx.model.repassword,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.repassword) = $event)),
                "prepend-icon": "mdi-key",
                label: "Confirm Password",
                placeholder: "Confirm Password",
                type: "password",
                class: "login-field",
                rules: [_ctx.validations.required, _ctx.validations.confirmPassword(_ctx.model.password)],
                outlined: ""
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectField, {
                outlined: "",
                dense: "",
                modelValue: _ctx.model.wayToKnow,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.wayToKnow) = $event)),
                items: _ctx.wayToKnows,
                "prepend-icon": "mdi-help-circle-outline",
                label: "How did you hear about us",
                placeholder: "How did you hear about us",
                class: "login-field"
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id", "onSubmit"]))
}