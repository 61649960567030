<template>
  <div>
    <v-card v-if="userLocation" class="mb-3">
      <v-card-text>
        Location: {{ userLocation }} <v-icon><VImg :src="userCountryFlag"></VImg></v-icon>
      </v-card-text>
    </v-card>
    <v-card :disabled="loading" class="mt-2">
      <v-card-text>
        <LastTwelveMonth @filterDataByDate="filterDataByDate" :selectLastMonth="selectLastMonth" />
      </v-card-text>
    </v-card>
    <v-card :loading="loading" class="mt-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <TimesheetChartReport :activities="activityReport" :technologies="technologyReport" :epics="epicReport" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <div
          v-if="
            !loading &&
            (!activityReport || activityReport.length === 0) &&
            (!technologyReport || technologyReport.length === 0) &&
            (!epicReport || epicReport.length === 0)
          "
          class="emptyResult d-flex justify-center mb-6"
        >
          <h1 class="pa-md-16 ma-16">{{ message }}</h1>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapMutations, mapGetters } from "vuex";
import { SHOW_LOADING, HIDDEN_LOADING } from "@/store/types";
import UserInfo from "shared-components/src/models/UserInfo";
import LastTwelveMonth from "@/components/range/LastTwelveMonth.vue";
import TimesheetChartReport from "@/components/report/TimesheetChartReport.vue";
import DashboardReportService from "@/services/DashboardReportService";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import UserService from "shared-components/src/services/UserService";
import { ActivityReport, EpicReport, TechnologyReport } from "shared-components/src/models/TeammemberDashboard";
import { UserApi } from "shared-components/src/services/openApi/api";
import Timesheet from "shared-components/src/models/Timesheet";
import store from "@/store";
var userApi = new UserApi();
interface Month {
  startDate: Date;
  endDate: Date;
}
export default defineComponent({
  data() {
    return {
      userLocation: "",
      userCountryFlag: "",
      loading: false,
      selectLastMonth: false,
      activityReport: [] as ActivityReport[],
      technologyReport: [] as TechnologyReport[],
      epicReport: [] as EpicReport[],
      filteredTimesheetsByDates: [] as Timesheet[],
      timesheets: [] as Timesheet[],
      selectedDates: [] as Month[],
      message: ProjectMessages.NoChartDetailInDates,
    };
  },
  components: {
    LastTwelveMonth,
    TimesheetChartReport,
  },
  computed: {
    ...mapGetters(["teamMemberInfo"]),
  },
  methods: {
    ...mapMutations([SHOW_LOADING, HIDDEN_LOADING]),
    setUserLocation(locationInfo: any) {
      if (locationInfo && locationInfo.location) {
        this.userLocation = `${locationInfo.location.city} - ${locationInfo.location.country.name}`;
        this.userCountryFlag = locationInfo.location.country.flag.emojitwo;
      }
    },
    async filterDataByDate(selectedFilters: any) {
      this.loading = true;
      this.activityReport = [] as ActivityReport[];
      this.technologyReport = [] as TechnologyReport[];
      this.epicReport = [] as EpicReport[];
      this.filteredTimesheetsByDates = [] as Timesheet[];
      if (selectedFilters.startDate) {
        var report = await DashboardReportService.getList(selectedFilters.startDate, selectedFilters.endDate);
        this.timesheets = report.timesheets;
        this.filteredTimesheetsByDates = report.timesheets;
        this.activityReport = report.activityReport;
        this.technologyReport = report.technologyReport;
        this.epicReport = report.epicReport;
        this.selectLastMonth = true;
      } else {
        if (!selectedFilters || selectedFilters.length === 0) {
          this.selectedDates = [] as Month[];
        } else {
          this.selectedDates = selectedFilters.map((c: any) => {
            return { startDate: c.startDate, endDate: c.endDate };
          });
        }
      }
      this.loading = false;
    },
    calculateActivities() {
      let activities = this.filteredTimesheetsByDates
        .filter((c) => c.ActivityId)
        .map((c) => {
          return { id: c.ActivityId, name: c.Activity, netEffort: c.Effort + c.Adjustment } as ActivityReport;
        });
      this.activityReport = activities.reduce((ac: ActivityReport[], a) => {
        let ind = ac.findIndex((x) => x.id === a.id);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);
    },
    calculateTechnologies() {
      let technologies = this.filteredTimesheetsByDates
        .filter((c) => c.TechnologyId)
        .map((c) => {
          return { id: c.TechnologyId, name: c.Technology, netEffort: c.Effort + c.Adjustment } as TechnologyReport;
        });
      this.technologyReport = technologies.reduce((ac: TechnologyReport[], a) => {
        let ind = ac.findIndex((x) => x.id === a.id);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);
    },
    calculateEpics() {
      let epics = this.filteredTimesheetsByDates.map((c) => {
        return { name: c.Epic, netEffort: c.Effort + c.Adjustment } as EpicReport;
      });
      this.epicReport = epics.reduce((ac: EpicReport[], a) => {
        let ind = ac.findIndex((x) => x.name === a.name);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);
    },
    async setUserLocationByIp() {
      var userInfo = store.state.userInfo;
      if (!userInfo.ipDetail) {
        var ipDetails = await userApi.getUserLocationByIp();
        if (ipDetails) {
          userInfo.ipDetail = ipDetails.data;
          store.dispatch("setUserInfo", { userInfo, vm: this });
        }
      }
      this.setUserLocation(userInfo.ipDetail);
    },
    filterCharts() {
      return this.timesheets.filter((item) => {
        let exist = true;
        if (this.selectedDates && this.selectedDates.length > 0) {
          exist = this.selectedDates.some((c) => item.Date && c.startDate <= item.Date && c.endDate > item.Date);
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
  },

  watch: {
    selectedDates(newVal: Month[]) {
      this.filteredTimesheetsByDates = this.filterCharts();
      this.calculateActivities();
      this.calculateTechnologies();
      this.calculateEpics();
    },
  },
  created() {
    this.SHOW_LOADING();
  },
  async mounted() {
    this.HIDDEN_LOADING();
    await this.setUserLocationByIp();
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/sizes.scss";
.emptyResult h1 {
  text-align: center;
  line-height: $normal;
}
</style>
