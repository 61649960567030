import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "user-name hidden-md-and-down" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationDropDown = _resolveComponent("NotificationDropDown")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_ctx.teamMemberInfo && _ctx.teamMemberInfo.Email)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["profile-dropdown", { 'is-open elevation-10': _ctx.profileDropdown }])
      }, [
        _createVNode(_component_NotificationDropDown, {
          updateNotificationListFlag: _ctx.updateNotificationListFlag,
          userId: _ctx.teamMemberInfo && _ctx.teamMemberInfo.UserAccountsId,
          onErrorRaise: _ctx.errorRaise,
          viewAllRoute: `/notifications`,
          onSuccessRaise: _ctx.successRaise,
          class: "notification-drp",
          isTeamMember: true
        }, null, 8, ["updateNotificationListFlag", "userId", "onErrorRaise", "onSuccessRaise"]),
        _createVNode(_component_v_menu, {
          modelValue: _ctx.profileMenu,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileMenu) = $event)),
          location: "bottom"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("div", _mergeProps({ class: "user-dropdown" }, props), [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.teamMemberInfo.FirstName + " " + _ctx.teamMemberInfo.LastName), 1),
              (_ctx.teamMemberInfo.PhotoUrl)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_img, {
                      src: _ctx.teamMemberInfo.PhotoUrl,
                      alt: "profile-photo",
                      class: "profile-photo"
                    }, null, 8, ["src"])
                  ]))
                : (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    color: _ctx.profileDropdown ? 'primary' : 'secondary',
                    class: "profile-photo"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("mdi-account-circle")
                    ])),
                    _: 1
                  }, 8, ["color"]))
            ], 16)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card, { "min-width": "300" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { class: "menu-user-email" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          (_ctx.emailVerified)
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                to: "/team-member/profile"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.teamMemberInfo.Email), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_router_link, {
                                key: 1,
                                to: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.teamMemberInfo.Email), 1)
                                ]),
                                _: 1
                              }))
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list, { class: "" }, {
                      default: _withCtx(() => [
                        (_ctx.commitments && _ctx.commitments.length > 0)
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 0,
                              to: "/team-member/timesheet"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_content, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                                        _createTextVNode("Timesheet")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_list_item, { to: "/register/logout" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_content, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("Log Out")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ], 2))
    : _createCommentVNode("", true)
}