<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>{{ positionDetails.Title != undefined ? `Position Title: ${positionDetails.Title}` : ""  }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row gutter="4">
                <v-col cols="12">
                  <p>Job Titles: {{ positionDetails.JobTitles?.length != 0 ?  positionDetails.JobTitles?.join(", ") : "undefined" }}</p>
                </v-col>
                <v-col cols="12">
                  <p>Job Description: {{ positionDetails.JobDescription ?? "undefined" }}</p>
                </v-col>
                <v-col cols="12">
                  <p>Skills: {{ positionDetails.Skills?.length != 0 ? positionDetails.Skills?.join(", ") : "undefined" }}</p>
                </v-col>
                <v-col cols="12">
                  <p>{{ positionDetails.Country != undefined ? `Country: ${positionDetails.Country}` : "" }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="onCancel">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onSave">Apply</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PositionApi, TeammemberPositionResponseModel } from "shared-components/src/services/openApi";
import Utils from "shared-components/src/utils/Utils"
import store from "@/store";


export default defineComponent({
  name: "ConfirmationPage",
  data() {
    return {
      positionId: "" as string,
      positionInfo: "" as string,
      positionDetails: {} as TeammemberPositionResponseModel,
      sourceOfRequest: "" as string,
    };
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.positionInfo = Utils.base64Decode(urlParams.get("position") ?? "") ?? "";
    const params = new URLSearchParams(this.positionInfo);
    this.positionId = params.get("positionId") ?? "";
    this.sourceOfRequest = params.get("platform") ?? "";
  
    try {
      this.positionDetails = (await new PositionApi().getPositionDetailsForTeammemberApplication(this.positionId)).data;
    } catch (error) {
      store.dispatch("showErrorMessage", "Failed to retrieve the position");
    }
  },
  methods: {
    goToProfilePage() {
      this.$router.push("/team-member/profile");
      window.location.href = "/team-member/profile";
    },
    async onCancel() {
      store.dispatch("showErrorMessage", "You cancelled your application for the position");
      await new Promise(resolve => setTimeout(resolve, 4000));
      this.goToProfilePage();
    },
    async onSave() {
      try {
        var rsp = await new PositionApi().applyForPosition(this.positionId, this.sourceOfRequest);
        store.dispatch("showSuccessMessage", rsp.data);
        await new Promise(resolve => setTimeout(resolve, 4000));
        this.goToProfilePage();
        
      } catch (error: any) {
        store.dispatch("showErrorMessage", error.response.data);
      }
    },
  },
});
</script>
