export const SET_USER_INFO = "SET_USER_INFO";
export const SET_TEAMMEMBER_INFO = "SET_TEAMMEMBER_INFO";
export const OPEN_QUICK_LOGIN_DIALOG = "OPEN_QUICK_LOGIN_DIALOG";
export const CLOSE_QUICK_LOGIN_DIALOG = "CLOSE_QUICK_LOGIN_DIALOG";
export const TOGGLE_DRAWER_OPEN = "TOGGLE_DRAWER_OPEN";
export const HANDLE_DRAWER_OPEN = "HANDLE_DRAWER_OPEN";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDDEN_LOADING = "HIDDEN_LOADING";
export const SET_SNAKBAR_MODEL = "SET_SNAKBAR_MODEL";
export const TOGGLE_SNAKBAR = "TOGGLE_SNAKBAR";
export const REMOVE_SNAKBAR = "REMOVE_SNAKBAR";
export const REMOVE_ALL_SNAKBARS = "REMOVE_ALL_SNAKBARS";
export const TOGGLE_PROFILE_DROPDOWN = "TOGGLE_PROFILE_DROPDOWN";
export const CLOSE_PROFILE_DROPDOWN = "CLOSE_PROFILE_DROPDOWN";
export const TOGGLE_NOTIFICATION_DROPDOWN = "TOGGLE_NOTIFICATION_DROPDOWN";
export const CLOSE_NOTIFICATION_DROPDOWN = "CLOSE_NOTIFICATION_DROPDOWN";
export const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
export const ADD_COUNTRIES = "ADD_COUNTRIES";
export const ADD_CITIES = "ADD_CITIES";
