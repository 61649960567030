import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "profileSetupTab" }
const _hoisted_2 = { class: "wizard-action" }
const _hoisted_3 = { class: "d-flex ga-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_InfoBox = _resolveComponent("InfoBox")!
  const _component_UploadProfilePhoto = _resolveComponent("UploadProfilePhoto")!
  const _component_UploadResume = _resolveComponent("UploadResume")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_TextAreaFieldWithAIGenerator = _resolveComponent("TextAreaFieldWithAIGenerator")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          class: "text-center text-md-left"
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createElementVNode("h1", null, "Profile Photo and Resume", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoBox, { title: "Upload a Profile Photo and a Copy of your Resume" })
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "6",
          lg: "4",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UploadProfilePhoto, {
              personalDetail: _ctx.personalDetail,
              onSetImage: _ctx.setImage,
              isFileValid: _ctx.validFile
            }, null, 8, ["personalDetail", "onSetImage", "isFileValid"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          md: "6",
          lg: "4",
          cols: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UploadResume, {
              personalDetail: _ctx.personalDetail,
              isFileValid: _ctx.validResume,
              onSetResume: _ctx.setResume
            }, null, 8, ["personalDetail", "isFileValid", "onSetResume"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          class: "text-center text-md-left"
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createElementVNode("h1", null, "Links", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_v_form, {
          ref: "frmProfileDetail",
          "lazy-validation": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_InfoBox, { title: "Submit any Links to your work or personal sites here!" })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              class: "pb-0"
                            }, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createElementVNode("span", { class: "f-body-1" }, "Enter your Links Here ", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4",
                              sm: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextField, {
                                  readonly: _ctx.isHiringmanager,
                                  placeholder: "Linkedin address",
                                  dense: "",
                                  outlined: "",
                                  modelValue: _ctx.linkedIn,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.linkedIn) = $event))
                                }, null, 8, ["readonly", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4",
                              sm: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextField, {
                                  readonly: _ctx.isHiringmanager,
                                  dense: "",
                                  outlined: "",
                                  placeholder: "GitHub address",
                                  modelValue: _ctx.github,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.github) = $event))
                                }, null, 8, ["readonly", "modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4",
                              sm: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextField, {
                                  readonly: _ctx.isHiringmanager,
                                  dense: "",
                                  outlined: "",
                                  placeholder: "Personal Website/Portfolio",
                                  modelValue: _ctx.website,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.website) = $event))
                                }, null, 8, ["readonly", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-center text-md-left"
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("h1", null, "Bio", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { md: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_InfoBox, { title: "Write a Brief Bio to introduce yourself. This Will be used for your public profile" })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextAreaFieldWithAIGenerator, {
                                  options: _ctx.questions,
                                  id: "PersonalDetailDescription",
                                  counter: "",
                                  readonly: false,
                                  placeholder: "Write a brief summary of your professional experience",
                                  rules: [_ctx.validations.required, _ctx.validations.minCharDesc],
                                  modelValue: _ctx.description,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.description) = $event)),
                                  showRequired: "",
                                  outlined: ""
                                }, null, 8, ["options", "rules", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-center text-md-left"
            }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createElementVNode("h1", null, "Personality", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { md: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_InfoBox, { title: "Tell us a little about your personality. it helps us to know more about you! (this will not be displayed on your profile)" })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TextAreaFieldWithAIGenerator, {
                                  options: _ctx.options,
                                  readonly: _ctx.isHiringmanager,
                                  id: "ExperienceDetailDescription",
                                  placeholder: "E.G: What are your strengths and weaknesses? What is your demeanour at work, are you introverted/extroverted, do you like to work in teams or do you prefer to work solo?",
                                  counter: "",
                                  label: "How would you describe your personality?",
                                  rules: [_ctx.validations.required, _ctx.validations.minCharDesc],
                                  modelValue: _ctx.personality,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.personality) = $event)),
                                  outlined: ""
                                }, null, 8, ["options", "readonly", "rules", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }),
    (_ctx.isAfterSignUp)
      ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_tooltip, {
                top: "",
                attach: ""
              }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({ class: "secondary_btn" }, props, {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitForm(true))),
                    disabled: _ctx.submitting,
                    loading: _ctx.submitting,
                    "prepend-icon": "mdi-check"
                  }), {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("Save and exit")
                    ])),
                    _: 2
                  }, 1040, ["disabled", "loading"])
                ]),
                default: _withCtx(() => [
                  _cache[18] || (_cache[18] = _createElementVNode("span", null, "You can save your progress and return to the application process later", -1))
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_btn, {
                  class: "primary_btn actionButton",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goBack())),
                  disabled: _ctx.loading,
                  loading: _ctx.loading,
                  "prepend-icon": "mdi-chevron-left"
                }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("Back")
                  ])),
                  _: 1
                }, 8, ["disabled", "loading"]),
                _createVNode(_component_v_btn, {
                  class: "primary_btn actionButton",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submitForm(false))),
                  disabled: _ctx.submitting,
                  loading: _ctx.submitting,
                  "append-icon": "mdi-chevron-right"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Finish")
                  ])),
                  _: 1
                }, 8, ["disabled", "loading"])
              ])
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_v_card_actions, {
          key: 1,
          class: "mt-6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              md: "12",
              class: "text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  class: "primary_btn actionButton",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submitForm(false))),
                  disabled: _ctx.submitting,
                  loading: _ctx.submitting,
                  "prepend-icon": "mdi-check"
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("Save")
                  ])),
                  _: 1
                }, 8, ["disabled", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showSaveForLaterConfirm,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showSaveForLaterConfirm) = $event)),
      "max-width": "500",
      onKeydown: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.showSaveForLaterConfirm = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_confirm_dialog, {
          loading: _ctx.submitting,
          message: _ctx.confirmModel,
          onConfirm: _cache[9] || (_cache[9] = ($event: any) => (_ctx.save(true))),
          onCancel: _ctx.cancelSaving
        }, null, 8, ["loading", "message", "onCancel"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}