<template>
  <CompleteProfile :isWizard="false" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CompleteProfile from "../CompleteProfile.vue";

export default defineComponent({
  data() {
    return {};
  },
  components: {
    CompleteProfile,
  },
  computed: {},
  methods: {},
  watch: {},
  async mounted() {},
});
</script>

<style scoped lang="scss"></style>
